<template>
  <!-- 代理商审核页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 代理商姓名 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                  </a-form-item>
                </a-col>
                <!-- 审核结果 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="状态">
                    <a-select v-model="queryParam.result" allow-clear placeholder="请选择">
                      <a-select-option :value="1">待审核</a-select-option>
                      <a-select-option :value="2">已通过</a-select-option>
                      <a-select-option :value="3">已拒绝</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-button type="primary" @click="searchTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table :columns="columns" :data-source="data" :scroll="{ x: 1300 }" :loading="loading" :pagination="false">
            <!-- 审核状态插槽 -->
            <span slot="checkStatus" slot-scope="text">
              <a-tag style="line-height: 20px" :color="text == 1 ? 'orange' : text == 2 ? 'green' : 'red'">
                {{ text == 1 ? '待审核' : text == 2 ? '已通过' : text == 3 ? '已拒绝' : '' }}
              </a-tag>
            </span>

            <span slot="categoryNames" slot-scope="text">
              {{ text ? (text.split('').length > 20 ? text.substring(0, 20) + '...' : text) : '' }}
            </span>
            <!-- 入驻主体类型插槽 -->
            <span slot="type" slot-scope="text">
              {{ text == 1 ? '个体' : text == 2 ? '个体工商户' : text == 3 ? '公司企业' : '' }}
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.detailForm.detail(record)">详情</a>
              <span v-show="record.result == 1">
                <a-divider type="vertical" />
                <a @click="$refs.oneForm.one({ ids: [record.id], result: '2' })">通过</a>
                <a-divider type="vertical" />
                <a @click="$refs.oneForm.one({ ids: [record.id], result: '3' })">拒绝</a>
              </span>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 引入子组件 -->
    <detail-form ref="detailForm"></detail-form>
    <one-form @ok="getList" ref="oneForm"></one-form>
  </div>
</template>

<script>
import { agentApplyPageList } from '@/api/modular/mallLiving/agentApply'

import detailForm from './detailForm.vue'
import oneForm from './auditForm.vue'

const columns = [
  {
    title: '序号',
    align: 'left',
    width: '100px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '代理商姓名',
    align: 'left',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'ID号',
    dataIndex: 'idNo',
    align: 'center',
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '身份证号',
    dataIndex: 'cardNumber',
    width: '150px',
    key: 'cardNumber',
    align: 'left',
  },
  {
    title: '公司名称',
    dataIndex: 'companyName',
    key: 'companyName',
    align: 'left',
  },

  {
    title: '代理区域',
    dataIndex: 'cityName',
    key: 'cityName',
    align: 'left',
  },
  {
    title: '代理类目',
    dataIndex: 'categoryNames',
    key: 'categoryNames',
    align: 'left',
    scopedSlots: { customRender: 'categoryNames' },
  },
  {
    title: '电子邮箱',
    dataIndex: 'email',
    key: 'email',
    align: 'left',
  },
  {
    title: '审核状态',
    dataIndex: 'result',
    key: 'result',
    align: 'left',
    scopedSlots: { customRender: 'checkStatus' },
  },
  {
    title: '操作',
    align: 'left',
    width: '150px',
    dataIndex: 'action',
    fixed: 'right',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      checkStatus: '1',
      selectedRowKeys: [], //表格行的选中项数组
      selectRowId: [], //选中行的id数组
      queryParam: {},
      resultList: [
        { status: 1, name: '待审核' },
        { status: 2, name: '已通过' },
        { status: 3, name: '已拒绝' },
      ],
      enterMainTypeList: [
        { status: 1, name: '个体' },
        { status: 2, name: '个体工商户' },
        { status: 3, name: '公司企业' },
      ],
    }
  },
  components: {
    detailForm,
    oneForm,
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    searchTableData() {
      //校验手机号长度
      if (this.queryParam.mobile && this.queryParam.mobile.toString().length > 11) {
        this.$message.error('手机号码格式不正确，请重新输入')
        return
      }
      this.initTableData()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: this.queryParam,
        page: this.page,
      }
      agentApplyPageList(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
  // computed:{
  //   rowSelection() {
  //     return {
  //       onChange: (selectedRowKeys, selectRowData) => {
  //         this.selectedRowKeys = selectedRowKeys
  //         //遍历出选中项id的数组
  //         this.selectRowId = selectRowData.map((item)=>{
  //           return item.id
  //         })
  //       },
  //       getCheckboxProps: record => ({
  //         props: {
  //           disabled: record.result != 1   //如果已经被审核，禁用选择框
  //         },
  //       }),
  //     };
  //   }
  // }
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
img {
  width: 20px;
  height: 20px;
}
</style>
