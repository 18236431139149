<template>
    <a-modal
        title="详情"
        width='50%'
        :visible="visible"
        :destroyOnClose="true"
        @cancel="handleCancel"
        :footer="null"
    >
        <a-spin :spinning="confirmLoading">
            <a-row>
                <a-form layout="inline">
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="姓名">
                            <span>{{details.name}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="身份证号">
                            <span>{{details.cardNumber}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="公司名称">
                            <span>{{details.companyName}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="代理区域">
                            <span>{{details.cityName}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="电子邮箱">
                            <span>{{details.email}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="申请时间">
                            <span>{{details.createTime}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="银行卡类型">
                            <span>{{details.bankTypeName}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="卡号">
                            <span>{{details.bankCard}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="开户行">
                            <span>{{details.bankOfDeposit}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="社会信用代码">
                            <span>{{details.socialCreditCode}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="营业执照">
                            <viewer :images="[details.companyBusinessLicense]">
                                <img :src="details.companyBusinessLicense" alt="">
                            </viewer>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="认证公函">
                            <viewer :images="[details.certificationLetter]">
                                <img :src="details.certificationLetter" alt="">
                            </viewer>
                        </a-form-item>
                    </a-col>
                    <a-col :md="24" :sm="24">
                        <a-form-item  label="代理类目">
                            <div>{{ details.categoryNames }}</div>
                        </a-form-item>
                    </a-col>
                    <a-col :md="24" :sm="24">
                        <a-form-item  label="审核结果">
                            <span :style="{color: details.result==1?'orange':details.result==2?'green':details.result==3?'red':''}">
                                {{details.result==1?'待审核':details.result==2?'已通过':details.result==3?'已拒绝':''}}
                            </span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="24" :sm="24" v-if="details.result!=1">
                        <a-form-item  label="审核备注">
                            <span>{{details.remark}}</span>
                        </a-form-item>
                    </a-col>
                    <a-divider>法人信息</a-divider>
                    <a-col :md="24" :sm="24">
                        <a-form-item  label="姓名">
                            <span>{{details.legalRepresentativeName}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="身份证正面">
                            <viewer :images="[details.cardFront]">
                                <img :src="details.cardFront" alt="">
                            </viewer>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item  label="身份证反面">
                            <viewer :images="[details.cardBack]">
                                <img :src="details.cardBack" alt="">
                            </viewer>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item label="手持身份证照片：">
                            <viewer :images="[details.handHeldIdCard]">
                                <img :src="details.handHeldIdCard" />
                            </viewer>
                        </a-form-item>
                    </a-col>
                </a-form>
            </a-row>
        </a-spin>
    </a-modal>
</template>

<script>
import {  agentApplyDetail } from '@/api/modular/mallLiving/agentApply'

export default {
    data(){
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            details:{},
            form: this.$form.createForm(this),
        }
    },
    methods:{
        //初始化方法
        detail(record){
            this.visible = true
            this.confirmLoading = true
            //获取详情数据
            setTimeout(()=>{
                agentApplyDetail({id: record.id}).then((res)=>{
                    if(res.success){
                        this.details = res.data
                        this.confirmLoading = false
                    }
                }).finally((res)=>{
                    setTimeout(()=>{
                        this.confirmLoading = false
                    },5000)
                })
            },100)
            
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.visible = false
            this.details = {} //关闭之后清空
        }
    }
}
</script>
<style lang="less" scoped>
    .mytable{
        margin-bottom:70px;
        border-collapse:collapse;
        width:100% ;
        height:250px;
        .title {
            background: rgb(207, 248, 248);
            width:20%
        }
        td{
            border:2px solid rgb(228, 225, 225);
            padding:7px;
            font-size:15px;
            width:30%;
        }
    }
    img{
        height:100px;
    }
</style>